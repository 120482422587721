<template>
  <main class="flex flex-1 h-screen brandkit">
    <Header />

    <div class="relative grid w-full grid-cols-10 gap-4 pl-3 ml-16">
      <section
        class="relative z-10 flex flex-col h-screen col-span-7 px-10 pt-10 bg-white shadow-lg"
      >
        <div
          v-show="!loaded"
          class="absolute top-0 bottom-0 left-0 right-0 z-10 flex items-center justify-center bg-white bg-opacity-80"
        >
          <div class="relative z-20 px-8 py-2 bg-white rounded-lg shadow-lg">Loading...</div>
        </div>

        <div class="text-4xl font-semibold font-h text-primary">
          Brand Kit
        </div>

        <div class="grid grid-cols-2 gap-8 mt-6 leftContainer">
          <div class="col-span-1">
            <div class="mb-1 font-semibold font-h text-primary">Upload Intro</div>
            <UploadFile
              key="intro"
              name="intro"
              accept="video/*"
              type="video"
              :value="introValue"
              @onChange="onIntroChange"
            />
          </div>

          <div class="col-span-1">
            <div class="mb-1 font-semibold font-h text-primary">Upload Outro</div>
            <UploadFile
              key="outro"
              name="outro"
              accept="video/*"
              type="video"
              :value="outroValue"
              @onChange="onOutroChange"
            />
          </div>

          <div class="col-span-1">
            <div class="mb-1 font-semibold font-h text-primary">Logo</div>
            <UploadFile
              key="logo"
              name="logo"
              accept=".jpeg,.jpg,.png"
              type="image"
              :value="logoValue"
              @onChange="onLogoChange"
            />
          </div>
        </div>

        <!-- <pre>{{JSON.stringify(brandkit, 0, 2)}}</pre> -->
      </section>

      <section
        class="relative flex flex-col h-screen col-span-3 overflow-hidden bg-white border-l border-primary-300"
      >
        <div
          v-show="!loaded"
          class="absolute top-0 bottom-0 left-0 right-0 z-10 bg-white bg-opacity-80"
        />
        <div class="relative group bg-primary thumbnail">
          <!-- <div class="relative z-20 hidden p-8 text-lg font-semibold text-white group-hover:block font-h">
            Sample File.mp4
          </div> -->

          <img
            v-show="logoUrl && logoStyle"
            :src="logoUrl"
            :style="computedLogoStyles"
          />

          <div v-show="enableSubtitleFormatting" :style="computedSubtitleStyles">
            Subtitle Placeholder
          </div>
        </div>

        <div class="p-4 rightSettings">
          <div class="mb-4 text-lg font-semibold font-h text-primary">Brand Kit Settings</div>

          <div class="mb-6">
            <div class="mb-4 text-sm font-medium text-primary">Logo Alignment</div>
            <LogoAlignment
              :value="logoStyle"
              @onChange="onLogoStyleChange"
            />
          </div>

          <div>
            <div class="flex items-center mb-4 text-sm font-medium text-primary">
              Subtitles Formatting
              <VueSwitch class="ml-2" v-model="enableSubtitleFormatting" />
            </div>
            <SubtitleFormatting
              :disabled="!enableSubtitleFormatting"
              :value="subtitleStyle"
              @onChange="onSubtitleStyleChange"
            />
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { isEmpty } from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import Header from '@/components/Drive/core/Header.vue'
import { getBrandkitInfo } from '@/services/api/brandkit'
import VueSwitch from '@/components/base/inputs/Switch'

import UploadFile from '@/view/BrandKit/components/UploadFile.vue'
import LogoAlignment from '@/view/BrandKit/components/LogoAlignment.vue'
import SubtitleFormatting from '@/view/BrandKit/components/SubtitleFormatting.vue'

const defaultSubtitleStyle = {
  'bottom': '24px',
  'padding': '4px 8px',
  'font-family': 'Roboto',
  'font-size': '48px',
  'font-weight': '400', // 700
  'font-style': 'normal', // italic
  'color': '#E2462C',
  'background': '#FFF',
  'border-radius': '5px',
  'display': 'flex',
  'align-items': 'center',
  'justify-content': 'center',
  'z-index': 2,
  'width': '80%',
  'text-align': 'center'
}

export default {
  name: 'BrandKit',
  components: {
    Header,
    UploadFile,
    LogoAlignment,
    SubtitleFormatting,
    VueSwitch
  },
  data: function() {
    return {
      version: 'v1',
      tab: 'video',
      brandkit: {},
      saved: false,
      logoUrl: '',
      scale: 0.2111,
      loaded: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'app/user'
    }),
    introValue: function() {
      return this.brandkit.intro || {}
    },
    outroValue: function() {
      return this.brandkit.outro || {}
    },
    logoValue: function() {
      return this.brandkit.logo || {}
    },
    logoStyle: function() {
      return this.brandkit.logoStyle || {}
    },
    enableSubtitleFormatting: {
      get() {
        return this.brandkit ? this.brandkit.enableSubtitleFormatting || false : false
      },
      set(val) {
        this.saveBrandkit({ enableSubtitleFormatting: val })
      }
    },
    subtitleStyle: function() {
      return this.brandkit.subtitleStyle || {}
    },
    computedLogoStyles: function() {
      return this.getStyles({
        size: {
          height: '40px',
          width: '40px'
        },
        position: this.logoStyle || { // in percentage & relative from left bottom corner
          x: 95,
          y: 95
        },
        style: {
          'z-index': 2
        }
      })
    },
    computedSubtitleStyles: function() {
      return this.getStyles({
        position: { // in percentage & relative from left bottom corner
          x: 50,
          y: 0
        },
        style: this.transformFontSize({
          ...defaultSubtitleStyle,
          ...this.subtitleStyle
        })
      })
    }
  },
  methods: {
    saveBrandkit: async function(update) {
      await firebase.database().ref(`/${this.user.uid}/brandkit/${this.tab}/${this.version}`)
        .update(update)
    },
    onIntroChange: function(intro) {
      this.saveBrandkit({ intro })
    },
    onOutroChange: function(outro) {
      this.saveBrandkit({ outro })
    },
    onLogoChange: function(logo) {
      this.saveBrandkit({
        logo,
        ...(isEmpty(this.logoStyle) ? { logoStyle: { x: 92, y: 88 } } : {})
      })
    },
    onLogoStyleChange: function(logoStyle) {
      this.saveBrandkit({ logoStyle })
    },
    onSubtitleStyleChange: function(update) {
      this.saveBrandkit({
        subtitleStyle: {
          ...this.brandkit.subtitleStyle,
          ...update
        }
      })
    },
    getStyles: function({ size, position, cover, style }) {
      const { x, y } = position
      let transform = ''
      if (x) transform += 'translateX(-50%) '
      if (y) transform += 'translateY(50%)'
      const styles = {
        position: 'absolute',
        ...x ? { left: `${x}%` } : {},
        ...y ? { bottom: `${y}%` } : {},
        ...size,
        ...cover ? {} : { transform },
        ...style
      }

      return styles
    },
    transformFontSize: function(style) {
      let { 'font-size': fontSize, ...rest } = style
      if (this.scale && fontSize.includes('px')) fontSize = `${Number(fontSize.replace('px', '') * this.scale)}px`
      return { ...rest, 'font-size': fontSize }
    }
  },
  created: function() {
    // setup brandkit listner
    getBrandkitInfo(
      {
        userId: this.user.uid,
        type: this.tab,
        version: this.version
      },
      val => {
        this.loaded = true
        if (val) this.brandkit = val
      }
    )
  },
  mounted: function() {
    window.Intercom('update', {
      'hide_default_launcher': true
    })
  },
  watch: {
    logoValue: async function(val) {
      if (val && val.url) {
        this.logoUrl = await firebase.storage().ref(this.logoValue.url).getDownloadURL()
      } else {
        this.logoUrl = ''
      }
    }
  }

}
</script>

<style lang="scss" src="./index.scss" />
